import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useEffect, useState } from 'react';
import NavButton from './navButton';

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        //flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    toolbarLink: {
        margin: theme.spacing(1, 1.5),
    },
}));

export default function NavBar(props) {
    const classes = useStyles();

    const [matches, setmatches] = useState(window.matchMedia("(min-width: 500px)").matches);
    useEffect(() => {
        const handler = e => setmatches({ matches: e.matches });
        window.matchMedia("(min-width: 500px)").addListener(handler);
    });

    return (
        <AppBar color="default" elevation={0} className={classes.appBar}>
            <Container maxWidth="md">
                <Grid container justifyContent="center">
                    <NavButton text="EUGENE KUKLIN" fontSize={matches ? 24 : "4.5vw"} onClick={props.onClickMe} />
                    <Grid item xs></Grid>
                    <NavButton text="About" fontSize={matches ? 16 : "2.8vw"} onClick={props.onClickAbout} />
                    <NavButton text="Projects" fontSize={matches ? 16 : "2.8vw"} onClick={props.onClickPortfolio} />
                </Grid>
            </Container>
        </AppBar >
    )
}