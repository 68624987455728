import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    button: {
        borderRadius: 0,

        //minHeight: '60px',
    }
}));

export default function NavButton(props) {
    const classes = useStyles();

    return (
        <Button size="large" fullWidth={false} href={props.link} className={classes.button} style={{ fontSize: props.fontSize }} onClick={props.onClick}>
            {props.text}
        </Button>
    );
}